import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import LightningIconImage from "images/fast-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import { aboutUsDescription, ourValuesDescription, ourValuesHeading, ourVisionDescription, ourVisionDescription2, ourValues } from "helpers/utils";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About OnPulse</Subheading>}
        heading="We go beyond traditional recruiting."
        buttonRounded={false}
        description={aboutUsDescription}
        // primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We grow with you."
        buttonRounded={false}
        description={ourVisionDescription}
        description2={ourVisionDescription2}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading={ourValuesHeading}
        description={ourValuesDescription}
        cards={[
          {
            imageSrc: CustomerLoveIconImage,
            title: ourValues[0].title,
            description: ourValues[0].description
          },
          {
            imageSrc: ShieldIconImage,
            title: ourValues[1].title,
            description: ourValues[1].description
          },
          {
            imageSrc: LightningIconImage,
            title: ourValues[2].title,
            description: ourValues[2].description
          }
        ]}
        linkText=""
      />
      {/* <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
